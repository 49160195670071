html,
body,
#root,
.App {
  height: 100%;
}

.campaign-panel {
  width: 300px;
  background: #d8e7f1 0% 0% no-repeat padding-box;
  background-color: #d8e7f1 !important;
  box-shadow: 4px 22px 54px #695f971a !important;
  border-radius: 16px !important;
}
.actions-panel {
  width: 240px;
  height: 160px;
  border-radius: 16px !important;
  background-color: #ffffff !important;
}

.wrap {
  padding: 0.3rem 0.6rem 0.6rem 0.6rem;
}

.main-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rounded-corner-10 {
  border-radius: 10px !important;
}

.react-flow__handle.connectionindicator {
  pointer-events: all;
  cursor: crosshair;
  width: 10px;
  height: 25px;
  border-radius: 4px;
  opacity: 0.2;
}

.react-flow__panel {
  display: none;
}

.enlarged .logo-box {
  width: 70px !important;
}

.logo-box {
  background-color: #fff;
  height: 70px;
  /* width: 230px; */
  float: left;
  -webkit-transition: all 0s ease-out;
  transition: all 0s ease-out;
  border-bottom: 1px solid #deedf7;
  border-top-right-radius: 25px;
}

.appbar {
  background-color: #f6f9fc !important;
  padding: 0 10px 0 0 !important;
  position: fixed;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 100;
  border-bottom: 1px solid #deedf7;
}

.appbar .toolbar {
  padding-left: 0 !important;
}

.sidebar {
  width: 230px;
  background-color: #fff;
}

.wrapper {
  flex-grow: 1;
  height: 100%;
}

.save__controls {
  position: absolute;
  right: 180px;
  top: 50px;
  z-index: 4;
  font-size: 12px;
}

/* .save__controls button {
  margin-left: 5px;
  color: var(--unnamed-color-ffffff);
  text-align: center;
  letter-spacing: 0px;
  color: hwb(216 6% 11%);
  opacity: 1;
} */
.chatBotRoot {
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: 30px;
  top: 0;
}

.openModalBtn {
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 6px;
  background-color: cornflowerblue;
  color: white;
  cursor: pointer;
}

.plus-btn {
  border: none;
  border-radius: 50%;
  width: 50px;
  margin-left: 10px;
  border-color: black;
}

.react-flow__node-custom {
  background-color: rgba(0, 0, 0, 0) !important;
  border-radius: 16px;
}

.publish_btn {
  box-shadow: 0px 0px 0px 2px #4aa5dc;
  /* background: linear-gradient(to bottom, #4aa5dc 5%, #4aa5dc 100%); */
  background-color: #4aa5dc;
  border-radius: 29px;
  border: #4aa5dc;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 15px;
  padding: 6px 15px;
  text-decoration: none;
  /* text-shadow: 0px 1px 0px #1650e3; */
  margin-right: 30px;
}

.publish_btn:hover {
  background: linear-gradient(to bottom, #0e72ed 5%, #5790f2 100%);
  background-color: #0e72ed;
}

.publish_btn:active {
  position: relative;
  top: 1px;
}

.plus {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin-top: 6px;
  margin-left: 10px;
  background: linear-gradient(#fff 0 0), linear-gradient(#fff 0 0), #000;
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
  border-radius: 50%;
}

.alt {
  background: linear-gradient(#000 0 0), linear-gradient(#000 0 0);
  background-position: center;
  background-size: 50% 2px, 2px 50%;
  /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}

.widget button.sai {
  background-color: #f5707a;
  border-color: #f5707a;
  color: #fff;
  border-radius: 50%;
  padding: 0.1rem;
  font-size: 8px;
  border: none;
}

.widget button.sai:hover {
  background-color: #f34c59;
  border-color: #f34c59;
  color: #fff;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 16px;
  border: none;
}

/* New Subscriber Template CSS */

.text-end-subscriber button {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  margin-left: 875px;
}
.title-text {
  margin-left: 6px;
}

.tablerounededCorner-subscriber {
  border: 1px solid #eaeaea;
  background-color: #fff;
}
.content-page-subscribe {
  margin-left: 230px;
  /* overflow: scroll; */
  padding: "0 100px";
  min-height: 120vh;
  margin-top: 20px;
}
.schedule-broadcast Button {
  border: 1px solid rgba(0, 0, 0, 0.26);
  padding: 0 25px;
}
.schedule-broadcast .save-schedule {
  box-shadow: 0px 0px 0px 2px #4aa5dc;
  background-color: #4aa5dc;
  border: #4aa5dc;
  color: #ffffff;
  text-shadow: 0px 1px 0px #1650e3;
  background: linear-gradient(to bottom, #4aa5dc 5%, #4aa5dc 100%);
}
span.icon_down_dir {
  background-image: "";
  background-repeat: no-repeat;
  background-position: center;
  width: 18px;
  height: 18px;
  color: #1650e3;
}
.back-button {
  border: none;
  background: none;
  font-size: 15px;
  color: #4aa5dc;
  margin-top: 10px;
}
.error-message {
  color: #ff0000;
  margin-left: 16px !important;
  margin-bottom: 6px !important;
  font-size: 14px !important;
}
/* Subscriber Image Upload */
.image-upload {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-success {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;
  border: 0.5px solid #c7c6c3;
  background-color: #c7c6c3;
  padding: 4px 20px;
  border-radius: 5px;
}
