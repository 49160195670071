.cardDisplay {
    top: 199px;
    left: 518px;
    width: 330px;
    height: 207px;
    /* UI Properties */
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px rgba(224, 228, 238, 1);
    border-radius: 10px;
    opacity: 1;
    position: inherit;
    cursor: pointer;
}

.imageDisplay {
    top: 246px;
    left: 659px;
    width: 49px;
    height: 62px;
    /* UI Properties */
    opacity: 1;
}

.contentDisplay{
    top: 599px;
left: 582px;
/* UI Properties */
text-align: center;
font: normal normal medium 20px/19px Roboto;
letter-spacing: 0px;
color: rgba(0, 0, 0, 1);
opacity: 1;
}
.mainBlock{
    top: 237px;
left: 448px;
width: 470px;
/* UI Properties */
background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
box-shadow: 0px 5px 10px rgba(224, 228, 238, 1);
border-radius: 10px;
opacity: 1;
}
.linkImage{
    top: 288px;
left: 635px;
width: 96px;
height: 96px;
/* UI Properties */
opacity: 1;
}
.connectText{
    top: 411px;
left: 583px;

/* UI Properties */
text-align: center;
letter-spacing: 0px;
color: rgba(0, 0, 0, 1);
opacity: 1;
text-align: center;
}

.cardData{
    top: 256px;
left: 416px;
width: 534px;
height: 89px;
/* UI Properties */
background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 5px 10px #E0E4EE;
border-radius: 10px;
opacity: 1;
}