.topbar {
  width: 100%;
  height: 70px;
  background-color: #f6f9fc;
  position: sticky;
  top: 0;
  z-index: 999;
  border-bottom: 1px solid #deedf7;
}

.topbarWrapper {
  height: 100%;
  padding: 0px 20px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}
/* .logo {
  font-weight: bold;
  font-size: 30px;
  color: darkblue;
  cursor: pointer;
} */

.logo {
  max-width: 200px;
  cursor: pointer;
  height: 50px;
}
.logo-boxx {
  background-color: #fff;
  height: 60px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 60px;
  float: left;
  -webkit-transition: all 0s ease-out;
  transition: all 0s ease-out;
  border-bottom: 1px solid #deedf7;
  border-top-right-radius: 25px;
}
.topLeft {
  display: flex;
  width: 230px;
  justify-content: center;
  border-radius: 29px;
  /* margin-left: 400px; */

  height: 40px;
}
.topLeft p {
  margin-top: 10px;
  justify-content: center;
  font-size: 12;
  color: black;
}
.topRight {
  display: flex;
  align-items: center;
}

.topbarIconContainer {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  color: #555;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  padding-left: 0%;
}
.topIconBadge {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -5px;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.topAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
/* Custom Scrollbar using CSS */
.custom-scrollbar-css {
  overflow-y: scroll;
}

/* scrollbar width */
.custom-scrollbar-css::-webkit-scrollbar {
  width: 0px;
}
.custom-scrollbar-css:hover::-webkit-scrollbar {
  width: 8px;
}
/* scrollbar track */
.custom-scrollbar-css::-webkit-scrollbar-track {
  background: #eee;
}

/* scrollbar handle */
.custom-scrollbar-css::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #4aa5dc;
  background-image: linear-gradient(to top, #4aa5dc 0%, #4aa5dc 100%);
}
